// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o8a2X_B6q9zdySVFM0Gz{width:100%}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignCard/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.campaignCard\n  width: 100%   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"campaignCard": "o8a2X_B6q9zdySVFM0Gz"
};
export default ___CSS_LOADER_EXPORT___;
