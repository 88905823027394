// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V0znf_7wwd_nZzZ1Vtq0{color:var(--color-main) !important}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/TokenTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kCAAA","sourcesContent":[".thText\n  color: var(--color-main) !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thText": "V0znf_7wwd_nZzZ1Vtq0"
};
export default ___CSS_LOADER_EXPORT___;
